














































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FollowUpStatus, Meeting } from './models/Meeting.model'
import MeetingTranslations from './Meeting.translations'
import { SelectOption } from '@/generic/models/SelectOption'
import { CultureService } from '@/services/CultureService'

@Component
export default class MeetingCardComponent extends Vue {
  @Prop() private meeting!: Meeting
  @Prop() companyId!: number
  @Prop() statusData!: Array<SelectOption>
  @Prop() salaryData!: Array<SelectOption>
  @Prop() processStatusData!: Array<SelectOption>
  @Prop() private translations!: MeetingTranslations
  private culture: string = CultureService.getCulture()
  private seeDetails: boolean = false
  private followUpStatus = {
    'chipColor': 'mitm-light-red',
    'chipTextColor': 'mitm-red',
    'chipLabel': 'close',
    'status': this.statusData[this.meeting.followUpStatus || FollowUpStatus.NotSelected].label,
    'statusColor': 'text-mitm-light-red'
  }
  private needReloading: boolean = false
  private openStatus:number[] = [
    FollowUpStatus.AcceptedInPool,
    FollowUpStatus.SelectedForFirstInterview,
    FollowUpStatus.SelectedForSecondInterview,
    FollowUpStatus.WaitingForDocuments,
    FollowUpStatus.InterviewPostponed,
    FollowUpStatus.JobPromise
  ]

  mounted () {
    if (this.meeting.followUpStatus !== undefined && this.meeting.followUpStatus !== null) {
      this.updateFollowUpStatus(this.meeting.followUpStatus)
    }
  }

  private toggleDialog () : void {
    this.seeDetails = !this.seeDetails
  }

  private getFollowUpStatus () {
    return this.followUpStatus
  }

  private getFollowUpProcessStatusColor() {
    if (this.meeting.processStatus === 1) {
      return 'green'
    }
    return 'grey'
  }

  private getFollowUpProcessStatusLabel() {
    if (this.meeting.processStatus === 1) {
      return this.processStatusData.find(x => x.value === 1)?.label
    }
    return this.processStatusData.find(x => x.value === 2)?.label
  }

  private updateFollowUpStatus (followUpStatus: FollowUpStatus) {
    if (this.openStatus.includes(followUpStatus)) {
      this.followUpStatus = {
        'chipColor': 'brand',
        'chipTextColor': 'white',
        'chipLabel': 'open',
        'status': this.statusData[followUpStatus].label,
        'statusColor': 'text-brand'
      }
    } else if (followUpStatus === FollowUpStatus.OfferAcceptedByCandidate) {
      this.followUpStatus = {
        'chipColor': 'mitm-light-green',
        'chipTextColor': 'mitm-green',
        'chipLabel': 'open',
        'status': this.statusData[followUpStatus].label,
        'statusColor': 'text-brand'
      }
    }
  }

  private updateFollowUpNeededAfterSave () : void {
    this.needReloading = true
  }

  private hide () {
    if (this.needReloading) {
      this.$emit('hide', this.needReloading)
      this.needReloading = false
    }
  }

  private formatMeetingDateTime(meeting: Meeting) {
    if (meeting.isVirtual) {
      return new Date(meeting.recruiterDateStart + 'Z').toLocaleDateString(this.culture + '-CA', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) + ' - ' + new Date(meeting.recruiterDateEnd + 'Z').toLocaleTimeString(this.culture + '-CA', { hour: 'numeric', minute: '2-digit', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
    } else {
      return new Date(meeting.recruiterDateStart).toLocaleDateString(this.culture + '-CA', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }) + ' - ' + new Date(meeting.recruiterDateEnd).toLocaleTimeString(this.culture + '-CA', { hour: 'numeric', minute: '2-digit' })
    }
  }
}
