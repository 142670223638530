











import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'
import PaymentConfirmationTranslations from '../models/PaymentConfirmation.translations'
import { ShoppingCart } from '../models/ShoppingCart'
import { ShoppingCartService } from '@/services/ShoppingCartService'
import { loadWhile } from '@/generic/helpers'

@Component
export default class PaymentConfirmationComponent extends Vue {
  @Prop() translations!: PaymentConfirmationTranslations
  @Prop() errors!: CommonErrorTranslations
  @Prop() orderNumber!: number
  @Prop() publishKey!: string
  @Prop() culture!: string
  private shoppingCart!: ShoppingCart

  mounted() {
    loadWhile(this, this.translations.common.message.loading, () =>
      Promise.all([
        ShoppingCartService.getShoppingCart('fr-CA', false)
          .then((response) => {
            this.shoppingCart = response.data
            this.deleteShoppingCart(this.shoppingCart.cartId)
          })
      ])
    )
  }

  private deleteShoppingCart(cartId: number) {
    ShoppingCartService.deleteShoppingCart(cartId)
  }
}
