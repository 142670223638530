

















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { debounce, downloadFile, loadWhile } from '@/generic/helpers'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { SelectOption } from '@/generic/models/SelectOption'
import AdminConsoleBillingService from '../../services/AdminConsoleBillingService'
import { AdminOrder } from '../../models/billing/AdminOrder'
import AdminOrderCardComponent from './AdminOrderCard.vue'
import AdminBillingPageTranslations from '../../models/billing/Billing.translations'
import AdminConsoleService from '../../services/AdminConsoleService'

@Component({
  components: {
    'mitm-order-card': AdminOrderCardComponent
  }
})
export default class AdminBillingListComponent extends Vue {
  @Prop() translations!: AdminBillingPageTranslations
  private events !: SelectOption[]
  private selectedEvent!: SelectOption | null
  private orders: Array<AdminOrder> = []
  private hasError = false
  private cultureCode !: string
  private pageNumber = 1
  private loaded = false
  private searchTerms = ''
  private searchTransactionId = ''
  private startDate = this.getLastMonthDate()
  private endDate = this.getCurrentDate()
  private totalOrder = 0

  private debouncedLoad = debounce(() => {
    this.resetScroll()
  }, 20)

  private mounted(): void {
    const preferredCulture = CultureService.getCulture()
    if (preferredCulture === Culture.English) {
      this.cultureCode = 'en-US'
    } else {
      this.cultureCode = 'fr-CA'
    }
    this.load()
  }

  private get animationOutClass() {
    return 'animated ' + (this.$q.screen.lt.md ? 'slideOutRight' : 'fadeOut')
  }

  private eventSelected(eventSelected: SelectOption) {
    if (eventSelected && eventSelected.value !== '') {
      this.selectedEvent = eventSelected
    } else {
      this.selectedEvent = null
    }
    this.$forceUpdate()
  }

  getLastMonthDate(): string {
    var date = new Date()
    if (date.getMonth() === 0) {
      return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + '01'
    }

    date.setMonth(date.getMonth() - 1)
    return date.getFullYear() + '/' + date.getMonth() + 1 + '/' + '01'
  }

  getCurrentDate(): string {
    return new Date().toJSON().slice(0, 10).replace(/-/g, '/')
  }

  private load(): void {
    this.loaded = false
    loadWhile(this, this.translations.billingPage.label.loading, () => Promise.all([
      this.getOrders(1, (a: boolean | void) => { }),
      this.loadEvents((a: boolean | void) => { })
    ]))
      .finally(() => {
        this.loaded = true
      })
  }

  private resetScroll() {
    let scroll = (this.$refs['billing-scroll'] as any)
    if (scroll) {
      scroll.stop()
    }
    this.pageNumber = 1
    this.totalOrder = 0
    this.orders.splice(0)
    if (scroll) {
      scroll.reset()
      scroll.resume()
    }
  }

  private async loadEvents(done: Function): Promise<void> {
    this.hasError = false

    try {
      const result = await AdminConsoleService.getDropdownEvents(this.cultureCode)
      this.events = [{ label: this.translations.common.option.all, value: '' }, { label: this.translations.billingPage.label.ongoingRecruitement, value: '0-0' }]
      this.events = this.events.concat(result.data)
      done(true)
    } catch (error) {
      if (error) {
        this.hasError = true
      }
      done(true)
    }
  }

  private async getOrders(index: number, done: Function): Promise<void> {
    try {
      const response = await AdminConsoleBillingService.getOrders(
        this.pageNumber++,
        this.searchTerms,
        this.searchTransactionId,
        this.selectedEvent && this.selectedEvent.value !== undefined ? this.selectedEvent.value.toString() : '',
        this.startDate,
        this.endDate)
      if (response.data.orders.length > 0) {
        this.orders.push(...response.data.orders)
        this.totalOrder = response.data.count
        done()
      } else {
        done(true)
      }
    } catch (error) {
      if (error) {
        this.hasError = true
      }
      done(true)
    }
  }

  private async downloadOrders(): Promise<void> {
    try {
      const response = await AdminConsoleBillingService.downloadOrders(
        this.searchTerms,
        this.searchTransactionId,
        this.selectedEvent && this.selectedEvent.value !== undefined ? this.selectedEvent.value.toString() : '',
        this.startDate,
        this.endDate)

      downloadFile(response)
    } catch (error) {
      if (error) {
        this.hasError = true
      }
    }
  }

  private onSearchTermsChange(newsearchterms: string): void {
    this.searchTerms = newsearchterms
  }

  private onSearchTransactionIdChange(newsearchTransactionId: string): void {
    this.searchTransactionId = newsearchTransactionId
  }

  private onSearch(): void {
    this.debouncedLoad()
    this.load()
  }

  private onReset(): void {
    this.searchTerms = ''
    this.searchTransactionId = ''
    this.startDate = this.getLastMonthDate()
    this.endDate = this.getCurrentDate()
    this.debouncedLoad()
    this.load()
  }

  private onExport(): void {
    loadWhile(this, this.translations.billingPage.label.loading, () => Promise.all([
      this.downloadOrders()
    ]))
  }

  private dateOptions(date: Date) {
    return new Date(date) <= new Date()
  }
}
