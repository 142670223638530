import './polyfills'

import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

import EmployerDashboardComponent from '@/dashboard/employer/EmployerDashboard.vue'
import EmployerPackageComponent from '@/dashboard/employer-package/EmployerPackage.vue'
import MITMInputComponent from '@/generic/components/MITMInput.vue'
import MITMEditorComponent from '@/generic/components/MITMEditor.vue'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import MITMSection from '@/generic/components/MITMSection.vue'
import MITMSignOutComponent from '@/generic/components/MITMSignOut.vue'
import MITMCallToActionComponent from '@/generic/components/MITMCallToAction.vue'
import MITMAvailabilityExpansionComponent from '@/generic/components/MITMAvailabilityExpansion.vue'
import BasicInfoComponent from '@/company/basic-info/BasicInfo.vue'
import OfferAddressInfoComponent from '@/offer/address/OfferAddressInfo.vue'
import ActivityAreaComponent from '@/activity-area/ActivityArea.vue'
import AdditionalInformationComponent from '@/company/additionnal-info/AdditionalInformation.vue'
import MITMSelectInputComponent from '@/generic/components/MITMSelectInput.vue'
import OfferPrerequisiteQuestionComponent from '@/offer/prerequisite/OfferPrerequisiteQuestion.vue'
import OfferPrerequisiteQuestionsComponent from '@/offer/prerequisite/OfferPrerequisiteQuestions.vue'
import JobOfferWorkInfosComponent from '@/offer/jobOfferWorkInfos/JobOfferWorkInfosComponent.vue'
import JobOfferRequirementComponent from '@/offer/jobOfferRequirement/RequirementComponent.vue'
import FirstOfferComponent from '@/offer/first/FirstOffer.vue'
import EditOfferComponent from '@/offer/edit/EditOffer.vue'
import EditCompanyComponent from '@/company/edit/EditCompany.vue'
import OfferDescriptionComponent from '@/offer/description/OfferDescription.vue'
import JobCountComponent from '@/offer/job-count/JobCount.vue'
import TitleIconComponent from '@/generic/components/TitleIcon.vue'
import HeaderIconComponent from '@/generic/components/HeaderIcon.vue'
import JobCardComponent from '@/offer/summary/JobCard.vue'
import OfferTitleComponent from '@/offer/title/OfferTitle.vue'
import ArticleListComponent from '@/dashboard/employer/articles/ArticleList.vue'
import SkillComponent from '@/offer/skill/Skill.vue'
import WorkExperienceComponent from '@/profile/work-experience/WorkExperience.vue'
import EducationComponent from '@/profile/education/Education.vue'
import LicenseComponent from '@/profile/license/License.vue'
import LanguageSelectorComponent from '@/offer/language/LanguageSelector.vue'
import ForgotPasswordComponent from '@/forgot-password/ForgotPassword.vue'
import ValidatePasswordComponent from '@/authentication/password/ValidatePassword.vue'
import ResetPasswordComponent from '@/reset-password/ResetPassword.vue'
import CompanyDescriptionComponent from '@/company/description/CompanyDescription.vue'
import ColleaguesComponent from '@/company/colleagues/ColleaguesComponent.vue'
import JobInfoFormComponent from '@/offer/info/JobInfoForm.vue'
import UserMenuComponent from '@/navigation/UserMenu.vue'
import LogoComponent from '@/company/logo/Logo.vue'
import MITMFileInputComponent from '@/generic/components/MITMFileInput.vue'
import SignInComponent from '@/authentication/sign-in/SignIn.vue'
import AuthentificationTitleComponent from '@/authentication/title/AuthentificationTitle.vue'
import AuthentificationEmployerComponent from '@/authentication/AuthentificationEmployer.vue'
import AuthentificationCandidateComponent from '@/authentication/AuthentificationCandidate.vue'
import SignUpComponent from '@/authentication/sign-up/SignUp.vue'
import EmployerMissionComponent from '@/dashboard/missions/EmployerMission.vue'
import FavoritesComponent from '@/dashboard/favorites/Favorites.vue'
import FavoritesForJobOfferComponent from '@/dashboard/favorites/FavoritesForJobOffer.vue'
import MissionRequestInformationFormComponent from '@/content/missions/MissionRequestInformationForm.vue'
import MissionsLandingPageComponent from '@/content/missions/MissionsLandingPage.vue'
import NewsletterFormComponent from '@/newsletter/NewsletterForm.vue'
import ExpertiseListComponent from '@/expertises/ExpertiseList.vue'
import ExpertiseComponent from '@/expertises/Expertise.vue'
import ExpertiseBlockComponent from '@/expertises/ExpertiseBlock.vue'
import ButtonImageComponent from '@/content/block/ButtonImage.vue'

import SVGIconComponent from '@/generic/components/SVGIcon.vue'
import OfferMenuTabComponent from '@/dashboard/offer-list/OfferMenuTab.vue'
import OfferGridComponent from '@/offer/offer-grid/OfferGrid.vue'
import PreviewOfferComponent from '@/offer/preview-offer/PreviewOffer.vue'
import PreviewOfferApplyComponent from '@/offer/preview-offer/PreviewOfferApply.vue'
import PreviewOfferContainerComponent from '@/offer/preview-offer/PreviewOfferContainer.vue'
import PreviewOfferCompanyComponent from '@/offer/preview-offer/PreviewOfferCompany.vue'
import AccountFormComponent from '@/authentication/account/AccountForm.vue'
import ConsentComponent from '@/authentication/consent/Consent.vue'
import CreateProfileComponent from '@/profile/CreateProfile.vue'
import LanguageProficiencyComponent from '@/profile/LanguageProficiency.vue'
import LanguageProficiencyCardComponent from '@/profile/LanguageProficiencyCard.vue'
import TitleAndSkillsComponent from '@/profile/TitleAndSkills.vue'
import JobOfferComponent from '@/job-offer/JobOffer.vue'
import JobApplicationComponent from '@/job-application/JobApplication.vue'
import PreSelectionQuestionsComponent from '@/job-application/PreSelectionQuestions.vue'
import JobOfferListComponent from '@/offer/job-offer-list/JobOfferList.vue'
import JobOfferCardComponent from '@/offer/job-offer-list/JobOfferCard.vue'
import CandidatesListComponent from '@/candidate/candidates-list/CandidatesList.vue'
import CandidateCardComponent from '@/candidate/candidates-list/CandidateCard.vue'
import CandidateProfileComponent from '@/candidate/profile/CandidateProfile.vue'
import CandidateDetailsComponent from '@/candidate/candidate-details/CandidateDetails.vue'
import CandidateOffersGridComponent from '@/candidate/candidate-offers/CandidateOffersGrid.vue'
import CandidateOffersTabMenuComponent from '@/candidate/candidate-offers/CandidateOffersTabMenu.vue'
import MissionsListComponent from '@/dashboard/missions/MissionList.vue'
import MissionCardComponent from '@/dashboard/missions/MissionCard.vue'
import SearchComponent from '@/search/Search.vue'
import MissionSelectComponent from '@/offer/mission-selector/MissionSelector.vue'
import JobApplicationCandidateDetailsComponent from '@/job-application/JobApplicationCandidateDetails.vue'
import MeetingDetailsComponent from '@/candidate/candidate-details/meeting-details/MeetingDetails.vue'
import MeetingDetailsRecComponent from '@/candidate/candidate-details/meeting-details/MeetingDetailsRec.vue'
import PdfPreviewComponent from '@/content/adobe-io/PdfPreview.vue'
import CompanyMissionScheduleComponent from '@/dashboard/missions/CompanyMissionSchedule.vue'
import RecruiterMissionScheduleComponent from '@/dashboard/missions/RecruiterMissionSchedule.vue'
import AllCompaniesMissionScheduleComponent from '@/admin/all-companies-mission-schedule/AllCompaniesMissionSchedule.vue'
import MeetingListComponent from '@/dashboard/meeting-list/MeetingList.vue'
import MeetingCardComponent from '@/dashboard/meeting-list/MeetingCard.vue'
import MeetingCardDetailsComponent from '@/dashboard/meeting-list/MeetingCardDetails.vue'

import AppLayoutComponent from '@/navigation/app/AppLayout.vue'
import AppHeaderComponent from '@/navigation/app/AppHeader.vue'
import AppMenuComponent from '@/navigation/app/AppMenu.vue'
import AuthLayoutComponent from '@/navigation/auth/AuthLayout.vue'
import AuthHeaderComponent from '@/navigation/auth/AuthHeader.vue'
import ContentLayoutComponent from '@/navigation/site/ContentLayout.vue'
import TestimonialBlockComponent from '@/navigation/site/TestimonialBlock.vue'
import DesktopHeaderComponent from '@/navigation/site/desktop/DesktopHeader.vue'
import DesktopMenuSectionComponent from '@/navigation/site/desktop/DesktopMenuSection.vue'
import MobileHeaderComponent from '@/navigation/site/mobile/MobileHeader.vue'
import MobileMenuComponent from '@/navigation/site/mobile/MobileMenu.vue'
import MobileMenuSectionComponent from '@/navigation/site/mobile/MobileMenuSection.vue'
import MobileUserMenuComponent from '@/navigation/site/mobile/MobileUserMenu.vue'
import HamburgerBtnComponent from '@/navigation/site/mobile/MobileHambergerButton.vue'
import CreateFirstOfferLayoutComponent from '@/navigation/create-first-offer/CreateFirstOfferLayout.vue'
import BreadcrumbComponent from '@/content/breadcrumb/Breadcrumb.vue'

import ImageCardComponent from '@/content/card/ImageCard.vue'
import MediaBlockComponent from '@/content/block/MediaBlock.vue'
import PromoBlockComponent from '@/content/block/PromoBlock.vue'
import LargeSharedContentBlockComponent from '@/content/block/LargeSharedContentBlock.vue'
import SmallSharedContentBlockComponent from '@/content/block/SmallSharedContentBlock.vue'
import JobBlockComponent from '@/content/block/JobBlock.vue'
import HeaderBlockComponent from '@/content/block/HeaderBlock.vue'
import TitleBlockComponent from '@/content/block/TitleBlock.vue'
import TextBlockComponent from '@/content/block/TextBlock.vue'
import SocialSharingBlockComponent from '@/content/block/SocialSharingBlock.vue'
import MissionMoreInfoBlockComponent from '@/content/missions/MissionMoreInfoBlock.vue'
import MissionOffersComponent from '@/content/missions/MissionOffers.vue'
import MissionPageHeaderComponent from '@/content/missions/MissionPageHeader.vue'
import MissionEventCardComponent from '@/content/missions/MissionEventCard.vue'
import ColumnImageBlockComponent from '@/content/block/ColumnImageBlock.vue'
import YoutubeVideoComponent from '@/content/youtube-video/YoutubeVideo.vue'
import ActivityAreaCarouselComponent from '@/content/carousel/ActivityAreaCarousel.vue'
import CarouselSlideColumnImageComponent from '@/content/carousel/CarouselSlideColumnImage.vue'
import InteractiveMapComponent from '@/content/interactive-map/InteractiveMap.vue'
import AnimatedArrowIconComponent from '@/generic/components/AnimatedArrowIcon.vue'
import MITMCarouselComponent from '@/generic/components/MITMCarousel.vue'
import EventBlockComponent from '@/content/block/EventBlock.vue'
import BulletPointBlockComponent from '@/content/block/BulletPointBlock.vue'
import ReconfirmComponent from '@/authentication/confirm/Reconfirm.vue'

import TextEditorComponent from '@/editors/TextEditor.vue'
import RichTextEditorComponent from '@/editors/RichTextEditor.vue'

import { dateFilter, hourFilter } from './generic/filters/Date'

import { ClickWhenNoDragDirective } from './generic/directives/ClickWhenNoDrag.directive'

import CvSectionComponent from '@/profile/cv-links/CvSection.vue'
import LinksSectionComponent from '@/profile/cv-links/LinksSection.vue'
import MoreInfosComponent from '@/profile/more-info/MoreInfos.vue'

import VueRecaptcha from 'vue-recaptcha'
import Croppa from 'vue-croppa'

import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'
import VueGoodshareLinkedIn from 'vue-goodshare/src/providers/LinkedIn.vue'
import VueGoodshareEmail from 'vue-goodshare/src/providers/Email.vue'

import FooterComponent from '@/generic/components/MITMFooter.vue'
import BlockTimeComponent from '@/generic/components/MITMBlockTime.vue'

import EmployerMapComponent from '@/map/EmployerMap.vue'

import ImpersonationComponent from '@/admin/impersonation/Impersonation.vue'

import PageCarouselComponent from '@/content/carousel/PageCarousel.vue'

import VueAgile from 'vue-agile'

import VueGtm from 'vue-gtm'

// Console admin
import AdminConsoleComponent from '@/admin/console/components/AdminConsole.vue'
import AdminAuthenticateComponent from '@/admin/console/components/AdminAuthenticate.vue'
import AdminConsoleAppLayoutComponent from '@/navigation/app/AdminConsoleAppLayout.vue'
import AdminCandidatesListComponent from '@/admin/console/components/candidats/AdminCandidatesList.vue'
import AdminCandidateCardComponent from '@/admin/console/components/candidats/AdminCandidatCard.vue'
import AdminProductsListComponent from '@/admin/console/components/products/AdminProductsList.vue'
import AdminProductsFormComponent from '@/admin/console/components/products/AdminProductForm.vue'
import AdminProductAssociationFormComponent from '@/admin/console/components/products/AdminProductAssociationForm.vue'
import AdminProductCardComponent from '@/admin/console/components/products/AdminProductCard.vue'
import AdminCompaniesListComponent from '@/admin/console/components/companies/AdminCompaniesList.vue'
import AdminMeetingsListComponent from '@/admin/console/components/meetings/AdminMeetingsList.vue'
import AdminExternalMeetingsListComponent from '@/admin/console/components/meetings/external/AdminExternalMeetingsList.vue'
import AdminExternalMeetingCardComponent from '@/admin/console/components/meetings/external/AdminExternalMeetingCard.vue'
import AdminExternalMeetingDetailsComponent from '@/admin/console/components/meetings/external/AdminExternalMeetingDetails.vue'
import AdminMeetingCardComponent from '@/admin/console/components/meetings/AdminMeetingCard.vue'
import AdminMeetingFormComponent from '@/admin/console/components/meetings/AdminMeetingForm.vue'
import AdminMeetingCardDetailsComponent from '@/admin/console/components/meetings/AdminMeetingCardDetails.vue'
import AdminCompanyCardComponent from '@/admin/console/components/companies/AdminCompanyCard.vue'
import AdminEditCompanyComponent from '@/admin/console/components/companies/AdminEditCompany.vue'
import AdminConsoleEditRecruiterComponent from '@/admin/console/components/recruiters/AdminConsoleEditRecuiter.vue'
import AdminJobOffersComponent from '@/admin/console/components/jobOffers/AdminJobOffers.vue'
import AdminEditJobOfferComponent from '@/admin/console/components/jobOffers/AdminEditJobOffer.vue'
import AdminJobOffersListComponent from '@/admin/console/components/jobOffers/AdminJobOffersList.vue'
import AdminJobOfferCardComponent from '@/admin/console/components/jobOffers/JobOfferListCard.vue'
import AdminEditCandidateComponent from '@/admin/console/components/candidats/AdminEditCandidate.vue'
import AdminBillingListComponent from '@/admin/console/components/billing/AdminBillingList.vue'

// Shop
import EmployerShoppingCartComponent from '@/dashboard/employer-package/ShoppingCart/EmployerShoppingCartPage.vue'
import PaymentConfirmationComponent from '@/dashboard/employer-package/checkout/PaymentConfirmation.vue'
import EmployerCheckoutComponent from '@/dashboard/employer-package/checkout/EmployerCheckoutPage.vue'

Vue.use(VueAgile)

Vue.use(Croppa)

Vue.use(VueLazyload, {
  preLoad: 1.5,
  observer: false
})

Vue.use(VueGtm, {
  id: 'GTM-WM7C26R',
  enabled: (window as any).useGtm,
  loadScript: true
})

Vue.component('mitm-employer-dashboard', EmployerDashboardComponent)
Vue.component('mitm-employer-package', EmployerPackageComponent)
Vue.component('mitm-app-layout', AppLayoutComponent)
Vue.component('mitm-auth-header', AuthHeaderComponent)
Vue.component('mitm-auth-layout', AuthLayoutComponent)
Vue.component('mitm-app-header', AppHeaderComponent)
Vue.component('mitm-content-layout', ContentLayoutComponent)
Vue.component('mitm-testimonial', TestimonialBlockComponent)
Vue.component('mitm-app-menu', AppMenuComponent)
Vue.component('mitm-content-header-mobile', MobileHeaderComponent)
Vue.component('mitm-content-header-desktop', DesktopHeaderComponent)
Vue.component('mitm-menu-section', DesktopMenuSectionComponent)
Vue.component('mitm-mobile-menu-section', MobileMenuSectionComponent)
Vue.component('mitm-content-menu', MobileMenuComponent)
Vue.component('mitm-user-section', MobileUserMenuComponent)
Vue.component('mitm-hamburger-btn', HamburgerBtnComponent)
Vue.component('mitm-user-info', UserMenuComponent)
Vue.component('mitm-input', MITMInputComponent)
Vue.component('mitm-editor', MITMEditorComponent)
Vue.component('mitm-prompt', MITMPromptComponent)
Vue.component('mitm-section', MITMSection)
Vue.component('mitm-sign-out', MITMSignOutComponent)
Vue.component('mitm-cta', MITMCallToActionComponent)
Vue.component('mitm-question', OfferPrerequisiteQuestionComponent)
Vue.component('mitm-pre-req', OfferPrerequisiteQuestionsComponent)
Vue.component('mitm-work-info', JobOfferWorkInfosComponent)
Vue.component('mitm-job-requirement', JobOfferRequirementComponent)
Vue.component('mitm-company-basic-info-form', BasicInfoComponent)
Vue.component('mitm-company-additional-info-form', AdditionalInformationComponent)
Vue.component('mitm-job-address-info-form', OfferAddressInfoComponent)
Vue.component('mitm-job-info-form', JobInfoFormComponent)
Vue.component('mitm-activity-fields-form', ActivityAreaComponent)
Vue.component('mitm-select-input', MITMSelectInputComponent)
Vue.component('mitm-create-first-offer', FirstOfferComponent)
Vue.component('mitm-offer-description-form', OfferDescriptionComponent)
Vue.component('mitm-title-icon-form', TitleIconComponent)
Vue.component('mitm-header-icon', HeaderIconComponent)
Vue.component('mitm-job-card', JobCardComponent)
Vue.component('mitm-offer-title-basic-info-form', OfferTitleComponent)
Vue.component('mitm-article-list', ArticleListComponent)
Vue.component('mitm-number-posts', JobCountComponent)
Vue.component('mitm-skill-basic-info', SkillComponent)
Vue.component('mitm-work-experience-basic-info', WorkExperienceComponent)
Vue.component('mitm-education-basic-info', EducationComponent)
Vue.component('mitm-license-basic-info', LicenseComponent)
Vue.component('vue-recaptcha', VueRecaptcha)
Vue.component('mitm-forgot-password', ForgotPasswordComponent)
Vue.component('mitm-reset-password', ResetPasswordComponent)
Vue.component('mitm-company-description', CompanyDescriptionComponent)
Vue.component('mitm-logo', LogoComponent)
Vue.component('mitm-file-input', MITMFileInputComponent)
Vue.component('mitm-signin', SignInComponent)
Vue.component('mitm-auth-title', AuthentificationTitleComponent)
Vue.component('mitm-auth-employer', AuthentificationEmployerComponent)
Vue.component('mitm-auth-candidate', AuthentificationCandidateComponent)
Vue.component('mitm-signup', SignUpComponent)
Vue.component('mitm-language-selector', LanguageSelectorComponent)
Vue.component('mitm-edit-offer', EditOfferComponent)
Vue.component('mitm-edit-company', EditCompanyComponent)
Vue.component('svg-icon', SVGIconComponent)
Vue.component('mitm-meeting-list', MeetingListComponent)
Vue.component('mitm-meeting-card', MeetingCardComponent)
Vue.component('mitm-meeting-card-details', MeetingCardDetailsComponent)
Vue.component('mitm-offer-list-menu', OfferMenuTabComponent)
Vue.component('mitm-offer-grid', OfferGridComponent)
Vue.component('mitm-preview-offer', PreviewOfferComponent)
Vue.component('mitm-preview-offer-apply', PreviewOfferApplyComponent)
Vue.component('mitm-preview-offer-container', PreviewOfferContainerComponent)
Vue.component('mitm-preview-offer-company', PreviewOfferCompanyComponent)
Vue.component('mitm-edit-account', AccountFormComponent)
Vue.component('mitm-validate-password', ValidatePasswordComponent)
Vue.component('mitm-language-proficiency', LanguageProficiencyComponent)
Vue.component('mitm-language-proficiency-card', LanguageProficiencyCardComponent)
Vue.component('mitm-create-profil', CreateProfileComponent)
Vue.component('mitm-create-profil-title-skill', TitleAndSkillsComponent)
Vue.component('mitm-create-profil-cv', CvSectionComponent)
Vue.component('mitm-create-profil-link', LinksSectionComponent)
Vue.component('mitm-create-profil-more-infos', MoreInfosComponent)
Vue.component('mitm-candidate-profile', CandidateProfileComponent)
Vue.component('mitm-colleagues', ColleaguesComponent)
Vue.component('mitm-job-offer', JobOfferComponent)
Vue.component('mitm-job-application', JobApplicationComponent)
Vue.component('mitm-preselection-questions', PreSelectionQuestionsComponent)
Vue.component('mitm-job-offer-list', JobOfferListComponent)
Vue.component('mitm-job-offer-card', JobOfferCardComponent)
Vue.component('mitm-candidates-list', CandidatesListComponent)
Vue.component('mitm-candidate-card', CandidateCardComponent)
Vue.component('mitm-candidate-details', CandidateDetailsComponent)
Vue.component('vue-goodshare-facebook', VueGoodshareFacebook)
Vue.component('vue-goodshare-twitter', VueGoodshareTwitter)
Vue.component('vue-goodshare-linkedin', VueGoodshareLinkedIn)
Vue.component('vue-goodshare-email', VueGoodshareEmail)
Vue.component('mitm-candidate-offers-grid', CandidateOffersGridComponent)
Vue.component('mitm-candidate-offers-tab-menu', CandidateOffersTabMenuComponent)
Vue.component('mitm-account-consent', ConsentComponent)
Vue.component('mitm-search', SearchComponent)
Vue.component('mitm-text-editor', TextEditorComponent)
Vue.component('mitm-rich-text-editor', RichTextEditorComponent)
Vue.component('mitm-image-card', ImageCardComponent)
Vue.component('mitm-media-block', MediaBlockComponent)
Vue.component('mitm-promo', PromoBlockComponent)
Vue.component('mitm-shared-block', LargeSharedContentBlockComponent)
Vue.component('mitm-small-shared-block', SmallSharedContentBlockComponent)
Vue.component('mitm-job-block', JobBlockComponent)
Vue.component('mitm-header-text', HeaderBlockComponent)
Vue.component('mitm-title', TitleBlockComponent)
Vue.component('mitm-text', TextBlockComponent)
Vue.component('mitm-social-sharing-block', SocialSharingBlockComponent)
Vue.component('mitm-missions-list', MissionsListComponent)
Vue.component('mitm-mission-card', MissionCardComponent)
Vue.component('mitm-mission-offers', MissionOffersComponent)
Vue.component('mitm-mission-page-header', MissionPageHeaderComponent)
Vue.component('mitm-mission-event-card', MissionEventCardComponent)
Vue.component('mitm-mission-selector', MissionSelectComponent)
Vue.component('mitm-mission-more-info-block', MissionMoreInfoBlockComponent)
Vue.component('mitm-column-image', ColumnImageBlockComponent)
Vue.component('mitm-youtube-video', YoutubeVideoComponent)
Vue.component('mitm-job-application-candidate-details', JobApplicationCandidateDetailsComponent)
Vue.component('mitm-footer', FooterComponent)
Vue.component('mitm-block-time', BlockTimeComponent)
Vue.component('mitm-activity-area-carousel', ActivityAreaCarouselComponent)
Vue.component('mitm-carousel-slide-column-image', CarouselSlideColumnImageComponent)
Vue.component('mitm-animated-arrow-icon', AnimatedArrowIconComponent)
Vue.component('mitm-create-first-offer-layout', CreateFirstOfferLayoutComponent)
Vue.component('mitm-carousel', MITMCarouselComponent)
Vue.component('mitm-employer-mission', EmployerMissionComponent)
Vue.component('mitm-favorites', FavoritesComponent)
Vue.component('mitm-favorites-for-job-offer', FavoritesForJobOfferComponent)
Vue.component('mitm-employer-map', EmployerMapComponent)
Vue.component('mitm-availability-expansion', MITMAvailabilityExpansionComponent)
Vue.component('mitm-impersonate', ImpersonationComponent)
Vue.component('mitm-breadcrumb', BreadcrumbComponent)
Vue.component('mitm-meeting-details', MeetingDetailsComponent)
Vue.component('mitm-meeting-detailsRec', MeetingDetailsRecComponent)
Vue.component('mitm-pdf-prefiew', PdfPreviewComponent)
Vue.component('mitm-company-mission-schedule', CompanyMissionScheduleComponent)
Vue.component('mitm-recruiter-mission-schedule', RecruiterMissionScheduleComponent)
Vue.component('mitm-all-companies-mission-schedule', AllCompaniesMissionScheduleComponent)
Vue.component('mitm-mission-request-information-form', MissionRequestInformationFormComponent)
Vue.component('mitm-page-carousel', PageCarouselComponent)
Vue.component('mitm-event-block', EventBlockComponent)
Vue.component('mitm-interactive-map', InteractiveMapComponent)
Vue.component('mitm-bullet-point', BulletPointBlockComponent)
Vue.component('mitm-missions-landing-page', MissionsLandingPageComponent)
Vue.component('mitm-newsletter-form', NewsletterFormComponent)
Vue.component('mitm-expertise-list', ExpertiseListComponent)
Vue.component('mitm-expertise', ExpertiseComponent)
Vue.component('mitm-expertise-block', ExpertiseBlockComponent)
Vue.component('mitm-button-image', ButtonImageComponent)
Vue.component('mitm-reconfirm', ReconfirmComponent)
Vue.component('mitm-admin-console', AdminConsoleComponent)
Vue.component('mitm-admin-authenticate', AdminAuthenticateComponent)
Vue.component('mitm-admin-console-app-layout', AdminConsoleAppLayoutComponent)
Vue.component('mitm-admin-candidates-list', AdminCandidatesListComponent)
Vue.component('mitm-admin-candidate-card', AdminCandidateCardComponent)
Vue.component('mitm-admin-products-list', AdminProductsListComponent)
Vue.component('mitm-admin-product-form', AdminProductsFormComponent)
Vue.component('mitm-admin-product-association-form', AdminProductAssociationFormComponent)
Vue.component('mitm-admin-product-card', AdminProductCardComponent)
Vue.component('mitm-admin-meetings-list', AdminMeetingsListComponent)
Vue.component('mitm-admin-external-meetings-list', AdminExternalMeetingsListComponent)
Vue.component('mitm-admin-external-meeting-card', AdminExternalMeetingCardComponent)
Vue.component('mitm-admin-external-meeting-details', AdminExternalMeetingDetailsComponent)
Vue.component('mitm-admin-meeting-card', AdminMeetingCardComponent)
Vue.component('mitm-admin-meeting-form', AdminMeetingFormComponent)
Vue.component('mitm-admin-meeting-details', AdminMeetingCardDetailsComponent)
Vue.component('mitm-admin-companies-list', AdminCompaniesListComponent)
Vue.component('mitm-admin-company-card', AdminCompanyCardComponent)
Vue.component('mitm-admin-edit-company', AdminEditCompanyComponent)
Vue.component('mitm-admin-edit-recuiter', AdminConsoleEditRecruiterComponent)
Vue.component('mitm-admin-job-offers', AdminJobOffersComponent)
Vue.component('mitm-admin-edit-offer', AdminEditJobOfferComponent)
Vue.component('mitm-admin-list-offer', AdminJobOffersListComponent)
Vue.component('mitm-admin-offer-card', AdminJobOfferCardComponent)
Vue.component('mitm-admin-edit-candidate', AdminEditCandidateComponent)
Vue.component('mitm-admin-billing-list', AdminBillingListComponent)
Vue.component('mitm-employer-shopping-cart-page', EmployerShoppingCartComponent)
Vue.component('mitm-employer-payment-confirmation-page', PaymentConfirmationComponent)
Vue.component('mitm-employer-checkout-page', EmployerCheckoutComponent)

Vue.filter('date', dateFilter)
Vue.filter('hour', hourFilter)

Vue.directive('click-when-no-drag', ClickWhenNoDragDirective)

declare global {
  interface Window {
    Vue: any
  }
}
window.Vue = Vue || {}
