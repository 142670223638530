export class Meeting {
    id: number
    recruiterId: number
    candidateId: number
    jobOfferId : number
    missionId : number
    isVirtual : boolean
    companyId : number
    recruiterDateStart: string
    recruiterDateEnd: string
    candidateDateStart: string
    candidateDateEnd: string
    recruiterFullName: string
    candidateFullName : string
    companyName : string
    jobOfferName : string
    missionName : string
    candidateExperienceTitle: string
    candidateExperienceDegree: string
    followUpStatus?: FollowUpStatus
    followUpNeeded: boolean
    processStatus?: ProcessStatus

    constructor () {
      var now = new Date()
      this.id = 0
      this.recruiterId = 0
      this.missionId = 0
      this.isVirtual = false
      this.companyId = 0
      this.candidateId = 0
      this.jobOfferId = 0
      this.recruiterDateStart = now.toLocaleDateString('en-US', this.getDateOptions)
      this.candidateDateStart = now.toLocaleDateString('en-US', this.getDateOptions)
      now.setHours(now.getHours() + 1)
      this.recruiterDateEnd = now.toLocaleDateString('en-US', this.getDateOptions)
      this.candidateDateEnd = now.toLocaleDateString('en-US', this.getDateOptions)
      this.recruiterFullName = ''
      this.candidateFullName = ''
      this.companyName = ''
      this.jobOfferName = ''
      this.missionName = ''
      this.candidateExperienceTitle = ''
      this.candidateExperienceDegree = ''
      this.followUpNeeded = false
    }

    public get getDateOptions () : object {
      return { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }
    }
}

export enum FollowUpStatus {
  AcceptedInPool,
  SelectedForFirstInterview,
  SelectedForSecondInterview,
  WaitingForDocuments,
  DidNotPassTechnicalTests,
  NotSelected,
  JobPromise,
  WithdrawalAfterOffer,
  OfferAcceptedByCandidate,
  InterviewPostponed,
  NoShowForInterview
}

export enum ProcessStatus {
  Ongoing,
  Ended
}
