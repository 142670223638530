












































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { EmployerCheckoutTranslations } from '../models/EmployerCheckout.translations'
import { ShoppingCart } from '../models/ShoppingCart'
import { loadWhile } from '@/generic/helpers'
import { ShoppingCartService } from '@/services/ShoppingCartService'
import { PaymentService } from '@/services/PaymentService'
import { RequiredRule } from '@/generic/rules/Required'
import ProductContract from '../models/ProductContract'
import { ProductType } from '../models/ProductType'
import ShoppingCartSummaryComponent from '../ShoppingCart/ShoppingCartSummary.vue'
import { OrderService } from '@/services/OrderService'
import { PaymentType } from '@/admin/console/models/billing/AdminOrder'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { DialogResult } from '@/generic/models/DialogResult'

@Component({
  components: {
    'mitm-shopping-summary': ShoppingCartSummaryComponent
  }
})

export default class EmployerCheckoutComponent extends Vue {
  @Prop() translations!: EmployerCheckoutTranslations
  @Prop() companyId!: number
  @Prop() companyName!: string
  @Prop() culture!: string
  @Prop() publishKey!: string
  @Prop() recruiterEmail!: string
  private shoppingCart!: ShoppingCart
  private contracts!: ProductContract[]
  private currentStep = 1
  private stepRefs = ['step1', 'step2']
  private hasError: boolean = false
  private loaded: boolean = false
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    contract: [new RequiredRule().getValidator(this.translations.common.message.required)]
  }

  mounted() {
    loadWhile(this, this.translations.common.message.loading, () =>
      Promise.all([
        ShoppingCartService.getShoppingCart(this.culture, true)
          .then((response) => {
            this.shoppingCart = response.data
            this.shoppingCart.receiptEmail = this.recruiterEmail
          })
          .catch(() => {
            this.hasError = true
          }),
        ShoppingCartService.getProductsContracts(this.culture)
          .then((response) => {
            this.contracts = response.data
            this.contracts.forEach((x) => {
              x.url = ShoppingCartService.getDownloadProductContractUrl(x.attachmentGuid)
            })
          })
          .catch(() => {
            this.hasError = true
          })
          .finally(() => {
            this.loaded = true
          })
      ])
    )
  }

  get getTodayDate(): string {
    return new Date().toLocaleDateString(this.culture)
  }

  private pageButtonLabel(page: number): string | null {
    if (page < this.currentStep) {
      return null
    }
    return page.toString()
  }

  private changePageTo(page: number): void {
    if (page > this.currentStep) {
      if (this.currentStep === 1 && this.isAllContactsSigned()) {
        this.shoppingCart.contracts = this.contracts
        this.currentStep = page
      }
    } else {
      this.currentStep = page
    }
  }

  private isAllContactsSigned(): boolean {
    return (
      this.contracts.find(
        (c) => c.isSigned === false || c.signatoryName === '' || c.signatoryTitle === ''
      ) === undefined
    )
  }

  private pageButtonIcon(page: number): boolean {
    return page < this.currentStep
  }

  private pageButtonColor(page: number): string {
    if (page === this.currentStep) {
      return 'active-page'
    }
    if (page < this.currentStep) {
      return 'previous-page'
    }
    return 'next-page'
  }

  private goToNextPage(): void {
    this.changePageTo(this.currentStep + 1)
  }

  private goToPreviousPage(): void {
    this.changePageTo(this.currentStep - 1)
  }

  private async payWithCreditCard(): Promise<any> {
    if (this.culture === 'en-US') {
      this.shoppingCart.successUrl = '/en/employer/payment-confirmation'
      this.shoppingCart.cancelUrl = `/en/employer/complete-your-purchase/${this.shoppingCart.cartId}`
    } else {
      this.shoppingCart.successUrl = '/fr/employeur/confirmation-paiement'
      this.shoppingCart.cancelUrl = `/fr/employeur/finalisez-votre-achat/${this.shoppingCart.cartId}`
    }

    try {
      const response = await PaymentService.createOrUpdatePaymentIntent(this.shoppingCart)

      this.shoppingCart = response.data
      await OrderService.createOrder(this.shoppingCart, this.companyId, this.culture, PaymentType.CreditCard)

      location.href = this.shoppingCart.paymentLinkUrl
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log((error as any).response.data.message)
    }
  }

  private payWithInterac(): void {
    var modal = (this.$refs.payInteracConfirmationPrompt as MITMPromptComponent)
    modal.open()
  }

  private async onPayInteracConfirmationClosed (result: DialogResult) {
    if (result !== DialogResult.Ok) {
      return
    }

    try {
      await OrderService.createOrder(this.shoppingCart, this.companyId, this.culture, PaymentType.Interac)

      if (this.culture === 'en-US') {
        window.location.href = '/en/employer/payment-confirmation'
      } else {
        window.location.href = '/fr/employeur/confirmation-paiement'
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log((error as any).response.data.message)
    }
  }

  private signed(val: ProductContract) {
    this.$forceUpdate()
  }

  private getContractTranslation(contract: ProductContract): string {
    return contract.productType === ProductType.JobOffer
      ? this.translations.checkout.section.one.button.jobOffer
      : contract.productType === ProductType.Mission
        ? this.translations.checkout.section.one.button.mission
        : this.translations.checkout.section.one.button.permit
  }
}
