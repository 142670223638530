























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdminOrder, OrderStatus, PaymentType } from '../../models/billing/AdminOrder'
import AdminBillingPageTranslations from '../../models/billing/Billing.translations'
import OrderDetailsComponent from './OrderDetails.vue'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { DialogResult } from '@/generic/models/DialogResult'
import AdminConsoleBillingService from '../../services/AdminConsoleBillingService'

@Component({
  components: {
    'mitm-order-details': OrderDetailsComponent
  }
})
export default class AdminOrderCardComponent extends Vue {
  @Prop() private order !: AdminOrder
  @Prop() translations!: AdminBillingPageTranslations
  @Prop({ default: false }) private isNew!: boolean
  private seeOrder = false
  private loading = false

  get canMarkPaymentReceived() {
    return this.order.orderStatus === OrderStatus.New || this.order.orderStatus === OrderStatus.PaymentFailed
  }

  private showOrder() {
    this.seeOrder = true
  }

  private onMarkPaymentReceivedClicked() {
    (this.$refs.markPaymentReceivedConfirmationPrompt as MITMPromptComponent).open()
  }

  private onMarkPaymentReceivedConfirmationClosed(result: DialogResult) {
    if (result !== DialogResult.Ok) {
      return
    }

    AdminConsoleBillingService.markOrderPaymentReceived(this.order.id).then(response => {
      if (response.status === 200) {
        this.order.orderStatus = OrderStatus.PaymentReceived
      } else {
        this.order.orderStatus = OrderStatus.PaymentFailed
      }

      this.$forceUpdate()
    })
  }

  getOrderStatusLabel(): string {
    switch (this.order.orderStatus) {
      case OrderStatus.New:
        return this.translations.billingPage.order.status.new
      case OrderStatus.PaymentReceived:
        return this.translations.billingPage.order.status.paymentReceived
      case OrderStatus.PaymentFailed:
        return this.translations.billingPage.order.status.failed
      case OrderStatus.InProgress:
        return this.translations.billingPage.order.status.inProgress
      case OrderStatus.Completed:
        return this.translations.billingPage.order.status.completed
      case OrderStatus.Closed:
        return this.translations.billingPage.order.status.closed
      case OrderStatus.Canceled:
        return this.translations.billingPage.order.status.canceled
      case OrderStatus.Expired:
        return this.translations.billingPage.order.status.expired
      default:
        return ''
    }
  }

  getOrderStatusColor(): string {
    switch (this.order.orderStatus) {
      case OrderStatus.PaymentReceived:
        return 'green'
      case OrderStatus.PaymentFailed:
      case OrderStatus.Canceled:
      case OrderStatus.Expired:
        return 'red'
      default:
        return 'azure'
    }
  }

  getOrderPaymentTypeLabel(): string {
    switch (this.order.paymentType) {
      case PaymentType.CreditCard:
        return this.translations.billingPage.order.paymentType.creditCard
      case PaymentType.Interac:
        return this.translations.billingPage.order.paymentType.interac
      default:
        return this.translations.billingPage.order.paymentType.creditCard
    }
  }
}

