















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ShoppingCartItem } from '../models/ShoppingCartItem'
import ShoppingCartTranslations from '../models/ShoppingCart.translations'
import { SelectOption } from '@/generic/models/SelectOption'
import { DurationOptionType } from '../models/DurationOptionType'

@Component
export default class ShoppingCartCardComponent extends Vue {
  @Prop() translations!: ShoppingCartTranslations
  @Prop() item!: ShoppingCartItem
  @Prop() durationOptions!: Array<SelectOption>
  private hasError = false
  private selectedDuration: string = ''

  mounted() {
    if (this.item.isJobOfferDepartment) {
      if (this.item.productDurationType === DurationOptionType.oneMonth) {
        this.selectedDuration = this.durationOptions[0].label
      } else {
        this.selectedDuration = this.durationOptions[1].label
      }
    }
  }

  updated() {
    if (this.item.isJobOfferDepartment) {
      if (this.item.productDurationType === DurationOptionType.oneMonth) {
        this.selectedDuration = this.durationOptions[0].label
      } else {
        this.selectedDuration = this.durationOptions[1].label
      }
    }
  }

  private subtractQuantity() {
    this.item.cartItemUnits -= 1
    this.$emit('update-quantity', this.item)
  }

  private addQuantity() {
    this.item.cartItemUnits += 1
    this.$emit('update-quantity', this.item)
  }

  private deleteItem() {
    this.$emit('item-deleted', this.item.cartItemId)
  }

  private DurationChanged(value: SelectOption) {
    if (this.item.productDurationType !== value.value) {
      if (this.item.productDurationType === DurationOptionType.oneMonth) {
        this.item.productDurationType = DurationOptionType.oneYear
      } else {
        this.item.productDurationType = DurationOptionType.oneMonth
      }

      this.$emit('item-duration-changes', this.item)
    }
  }
}

