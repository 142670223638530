import { render, staticRenderFns } from "./EmployerShoppingCartPage.vue?vue&type=template&id=8b889a62&scoped=true&"
import script from "./EmployerShoppingCartPage.vue?vue&type=script&lang=ts&"
export * from "./EmployerShoppingCartPage.vue?vue&type=script&lang=ts&"
import style0 from "./EmployerShoppingCartPage.vue?vue&type=style&index=0&id=8b889a62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b889a62",
  null
  
)

export default component.exports