


























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'
import { loadWhile } from '@/generic/helpers'
import ShoppingCartTranslations from '../models/ShoppingCart.translations'
import { ShoppingCart } from '../models/ShoppingCart'
import { ShoppingCartService } from '@/services/ShoppingCartService'
import ShoppingCartCardComponent from './ShoppingCartCard.vue'
import { ShoppingCartItem } from '../models/ShoppingCartItem'
import ShoppingCartSummaryComponent from './ShoppingCartSummary.vue'
import { SelectOption } from '@/generic/models/SelectOption'
import { ProductJobOfferType } from '../models/ProductJobOfferType'
import { ProductService } from '@/services/ProductService'
import Product from '../models/Product'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'

@Component({
  components: {
    'mitm-shopping-item-card': ShoppingCartCardComponent,
    'mitm-shopping-summary': ShoppingCartSummaryComponent
  }
})
export default class EmployerShoppingCartComponent extends Vue {
  @Prop() translations!: ShoppingCartTranslations
  @Prop() errors!: CommonErrorTranslations
  @Prop() companyId!: number
  @Prop() culture!: string
  @Prop() durationOptions!: Array<SelectOption>

  private shoppingCart!: ShoppingCart
  private productsJobOffers!: Array<Product>
  private loaded = false
  private hasError = false

  mounted() {
    this.durationOptions = this.durationOptions.filter(obj => obj.value as number <= 1)
    loadWhile(this, this.translations.loading, () => Promise.all([
      ShoppingCartService.getShoppingCart(this.culture, true)
        .then(response => {
          this.shoppingCart = response.data
        })
        .catch(() => {
          this.hasError = true
        }),
      ProductService.getJobOffersProducts()
        .then(response => {
          this.productsJobOffers = response.data
        })
        .catch(() => {
          this.hasError = true
        })
    ]).finally(() => {
      this.loaded = true
    }))
  }

  updateQuantity(item: ShoppingCartItem) {
    if (item.isJobOfferDepartment) {
      if (item.productJobOfferType === ProductJobOfferType.OneOffer &&
        item.cartItemUnits > 4) {
        var jobOfferTypeOfFive = this.shoppingCart.cartItems.find(i => i.productJobOfferType === ProductJobOfferType.FiveOffer)
        if (jobOfferTypeOfFive !== undefined && jobOfferTypeOfFive.cartItemUnits === 1) {
          this.mergeCurrentItem(jobOfferTypeOfFive, ProductJobOfferType.TenOffer)
          this.deleteItem(item.cartItemId)
        } else {
          this.mergeCurrentItem(item, ProductJobOfferType.FiveOffer)
        }
      } else if (item.productJobOfferType === ProductJobOfferType.FiveOffer &&
        item.cartItemUnits > 1) {
        this.mergeCurrentItem(item, ProductJobOfferType.TenOffer)
      } else {
        this.updateItemQuantity(item.cartItemId, item.cartItemUnits, this.culture)
      }
    } else {
      this.updateItemQuantity(item.cartItemId, item.cartItemUnits, this.culture)
    }
  }

  mergeCurrentItem(item: ShoppingCartItem, offerType: ProductJobOfferType) {
    let productResult = this.productsJobOffers.find(p => p.productJobOfferType === offerType)
    if (productResult !== undefined) {
      let variant = productResult.variants.find(v => v.productDurationType === item?.productDurationType)
      if (variant !== undefined) {
        this.mergeItem(item.cartItemId, variant.skuGuid)
      }
    }
  }

  updateItemQuantity(cartItemId: number, cartItemUnits: number, culture: string) {
    loadWhile(this, this.translations.loading, () => Promise.all([
      ShoppingCartService.updateQuantity(cartItemId, cartItemUnits, culture, true)
        .then(response => {
          this.shoppingCart = response.data
        })
        .catch(() => {
          this.hasError = true
        }).finally(() => { this.$forceUpdate() })
    ]))
  }

  deleteItem(cartItemId: number) {
    loadWhile(this, this.translations.loading, () => Promise.all([
      ShoppingCartService.deleteItem(cartItemId, this.culture, true)
        .then(response => {
          this.shoppingCart = response.data
        })
        .catch(() => {
          this.hasError = true
        }).finally(() => {
          if (this.shoppingCart.cartItems.length === 0) {
            this.goToOffersPage()
          } else {
            this.$forceUpdate()
          }
        })
    ]))
  }

  itemDurationChanged(item: ShoppingCartItem) {
    loadWhile(this, this.translations.loading, () => Promise.all([
      ShoppingCartService.itemDurationChanged(item, this.culture, true)
        .then(response => {
          this.shoppingCart = response.data
        })
        .catch(() => {
          this.hasError = true
        }).finally(() => { this.$forceUpdate() })
    ]))
  }

  mergeItem(cartItemId: number, variantSkuGuid: string) {
    loadWhile(this, this.translations.loading, () => Promise.all([
      ShoppingCartService.mergeItem(cartItemId, variantSkuGuid, this.culture, true)
        .then((response) => {
          this.shoppingCart = response.data
        }).finally(() => {
          this.$forceUpdate()
        })
    ]))
  }

  private goToOffersPage() {
    if (CultureService.getCulture() === Culture.English) {
      window.location.href = '/en/employer/offers'
    } else {
      window.location.href = '/fr/employeur/offres'
    }
  }
}
