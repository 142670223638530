import { ShoppingCart } from '@/dashboard/employer-package/models/ShoppingCart'
import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'
import { PaymentType } from '@/admin/console/models/billing/AdminOrder'

export class OrderService {
  public static createOrder (shoppingCart: ShoppingCart, companyId: number, cultureCode: string, paymentType: PaymentType) : AxiosPromise<any> {
    return BASE_API.post('order/createOrder', shoppingCart, {
      params: {
        companyId: companyId,
        cultureCode: cultureCode,
        paymentType: paymentType
      } })
  }
}
