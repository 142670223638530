

























































































































































































































































import { ProductType } from '@/dashboard/employer-package/models/ProductType'
import { Culture } from '@/generic/models/Culture'
import { CultureService } from '@/services/CultureService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdminOrder, AdminOrderItem, OrderStatus } from '../../models/billing/AdminOrder'
import AdminBillingPageTranslations from '../../models/billing/Billing.translations'
import AdminConsoleBillingService from '../../services/AdminConsoleBillingService'
import { downloadFile } from '@/generic/helpers'

@Component
export default class OrderDetailsComponent extends Vue {
  @Prop() translations!: AdminBillingPageTranslations
  @Prop() private seeOrder!: boolean
  @Prop() private order!: AdminOrder
  private jobOffersItems!: AdminOrderItem[]
  private missionItems!: AdminOrderItem[]
  private cohortItems!: AdminOrderItem[]
  private permitItems!: AdminOrderItem[]
  private loaded = false
  private hasError = false
  private errorMessage = 'Error'

  mounted() {
    this.jobOffersItems = this.order.items.filter(o => o.productType === ProductType.JobOffer)
    this.missionItems = this.order.items.filter(o => o.productType === ProductType.Mission)
    this.cohortItems = this.order.items.filter(o => o.productType === ProductType.Cohort)
    this.permitItems = this.order.items.filter(o => o.productType === ProductType.Permit)
    this.loaded = true
  }

  getOrderStatusLabel(): string {
    switch (this.order.orderStatus) {
      case OrderStatus.New:
        return this.translations.billingPage.order.status.new
      case OrderStatus.PaymentReceived:
        return this.translations.billingPage.order.status.paymentReceived
      case OrderStatus.PaymentFailed:
        return this.translations.billingPage.order.status.failed
      case OrderStatus.InProgress:
        return this.translations.billingPage.order.status.inProgress
      case OrderStatus.Completed:
        return this.translations.billingPage.order.status.completed
      case OrderStatus.Closed:
        return this.translations.billingPage.order.status.closed
      case OrderStatus.Canceled:
        return this.translations.billingPage.order.status.canceled
      case OrderStatus.Expired:
        return this.translations.billingPage.order.status.expired
      default:
        return ''
    }
  }

  getOrderStatusColor(): string {
    switch (this.order.orderStatus) {
      case OrderStatus.PaymentReceived:
        return 'green'
      case OrderStatus.PaymentFailed:
      case OrderStatus.Canceled:
      case OrderStatus.Expired:
        return 'red'
      default:
        return 'azure'
    }
  }

  getPrice(price: number): string {
    if (CultureService.getCulture() === Culture.English) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(price).split('CA')[1]
    } else {
      return new Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD' }).format(price).split('CA')[0]
    }
  }

  private downloadInvoice() {
    let promise = AdminConsoleBillingService.downloadInvoice(this.order.id)

    promise
      .then(response => {
        if (response.status === 200) {
          downloadFile(response)
        }
      })
  }

  private downloadContracts() {
    let promise = AdminConsoleBillingService.downloadContracts(this.order.id)

    promise
      .then(response => {
        if (response.status === 200) {
          downloadFile(response)
        }
      })
  }
}
