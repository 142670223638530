














































































import { Culture } from '@/generic/models/Culture'
import { CultureService } from '@/services/CultureService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DurationOptionType } from '../models/DurationOptionType'
import { ProductJobOfferType } from '../models/ProductJobOfferType'
import { ShoppingCart } from '../models/ShoppingCart'
import ShoppingCartTranslations from '../models/ShoppingCart.translations'
import { ShoppingCartItem } from '../models/ShoppingCartItem'
import { loadWhile } from '@/generic/helpers'
import { ShoppingCartService } from '@/services/ShoppingCartService'

@Component
export default class ShoppingCartSummaryComponent extends Vue {
  @Prop() translations!: ShoppingCartTranslations
  @Prop() shoppingCart!: ShoppingCart
  @Prop() culture!: string
  @Prop({ default: false }) hideButtons!: boolean

  getPrice(price: number): string {
    if (CultureService.getCulture() === Culture.English) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(price).split('CA')[1]
    } else {
      return new Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD' }).format(price).split('CA')[0]
    }
  }

  getProductName(item: ShoppingCartItem): string {
    if (item.isJobOfferDepartment) {
      if (item.productJobOfferType === ProductJobOfferType.OneOffer && item.productDurationType === DurationOptionType.oneMonth) {
        return this.translations.summary.jobOffer.one.oneMonth
      } else if (item.productJobOfferType === ProductJobOfferType.OneOffer && item.productDurationType === DurationOptionType.oneYear) {
        return this.translations.summary.jobOffer.one.oneYear
      } else if (item.productJobOfferType === ProductJobOfferType.FiveOffer && item.productDurationType === DurationOptionType.oneMonth) {
        return this.translations.summary.jobOffer.five.oneMonth
      } else if (item.productJobOfferType === ProductJobOfferType.FiveOffer && item.productDurationType === DurationOptionType.oneYear) {
        return this.translations.summary.jobOffer.five.oneYear
      } else if (item.productJobOfferType === ProductJobOfferType.TenOffer && item.productDurationType === DurationOptionType.oneMonth) {
        return this.translations.summary.jobOffer.ten.oneMonth
      } else if (item.productJobOfferType === ProductJobOfferType.TenOffer && item.productDurationType === DurationOptionType.oneYear) {
        return this.translations.summary.jobOffer.ten.oneYear
      } else if (item.productJobOfferType === ProductJobOfferType.TwentyFiveOffer && item.productDurationType === DurationOptionType.oneMonth) {
        return this.translations.summary.jobOffer.twentyFive.oneMonth
      } else {
        return this.translations.summary.jobOffer.twentyFive.oneYear
      }
    } else if (item.isMissionDepartment) {
      return this.translations.summary.mission
    } else if (item.isCohortDepartment) {
      return this.translations.summary.cohort
    } else {
      return this.translations.summary.permit
    }
  }

  backToOffersPage() {
    if (CultureService.getCulture() === Culture.English) {
      window.location.href = '/en/employer/offers'
    } else {
      window.location.href = '/fr/employeur/offres'
    }
  }

  private goToCheckoutpage() {
    let id = this.shoppingCart.cartId
    this.updatePurchaseOrder(id, this.shoppingCart.purchaseOrder)

    if (CultureService.getCulture() === Culture.English) {
      window.location.href = `/en/employer/complete-your-purchase/${id}`
    } else {
      window.location.href = `/fr/employeur/finalisez-votre-achat/${id}`
    }
  }

  private updatePurchaseOrder(cartItemId: number, purchaseOrder: string) {
    loadWhile(this, this.translations.loading, () => Promise.all([
      ShoppingCartService.updatePurchaseOrder(cartItemId, purchaseOrder)
        .then(response => {
          this.shoppingCart = response.data
        })
        .catch(() => {
        }).finally(() => { this.$forceUpdate() })
    ]))
  }
}

