















































































































































































































import { Culture } from '@/generic/models/Culture'
import { CultureService } from '@/services/CultureService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EmployerPackageTranslations } from '../models/EmployerPackage.translations'
import Product from '../models/Product'
import { ShoppingCart } from '../models/ShoppingCart'
import { DurationOptionType } from '../models/DurationOptionType'
import { ProductPriceRange } from '@/admin/console/models/products/ProductsDetails.models'
import { SelectOption } from '@/generic/models/SelectOption'

@Component
export default class ProductCardComponent extends Vue {
  @Prop() translations!: EmployerPackageTranslations
  @Prop() product!:Product
  @Prop() shoppingCart!: ShoppingCart
  @Prop({ default: false }) private isMission!: boolean
  @Prop({ default: false }) isCohort!: boolean
  @Prop({ default: false }) private isJobOffer!: boolean
  @Prop({ default: false }) private isPermit!: boolean
  @Prop({ default: false }) private isLoading!: boolean
  @Prop() industriesList!: Array<SelectOption>
  private isFirstVariantSelected = true
  private isSecondVariantSelected = false
  private productHasDiscount = false
  private productFirstVariantHasDiscount = false
  private productSecondvariantHasDiscount = false
  selectedCohortPriceRange: ProductPriceRange = this.isCohort ? this.product.cohortPriceRange[0] : { max: 4, price: 0 }
  private quantity: number = 1
  private cultureCode = CultureService.getCulture() === Culture.English ? 'en-US' : 'fr-CA'

  mounted () {
    if (this.isMission) {
      this.isFirstVariantSelected = this.isMissionVariantEnabled(1)
      this.isSecondVariantSelected = !this.isFirstVariantSelected
    }

    this.initialiseHasDiscountvalues()
  }

  isProductEnabled () : boolean {
    if (this.product.variants.length > 0 && !this.isCohort) {
      if (this.isFirstVariantSelected) {
        if (this.isJobOffer) {
          return this.isVariantEnabled(DurationOptionType.oneMonth)
        } else {
          return this.isVariantEnabled(DurationOptionType.lessThanFifteen)
        }
      } else {
        if (this.isJobOffer) {
          return this.isVariantEnabled(DurationOptionType.oneYear)
        } else {
          return this.isVariantEnabled(DurationOptionType.moreThanFifteen)
        }
      }
    } else {
      return this.product.enabled
    }
  }

  isVariantEnabled(duration: DurationOptionType) : boolean {
    let variant = this.product.variants.find(v => v.productDurationType === duration)
    return variant !== undefined && variant.enabled
  }

  isMissionVariantEnabled(duration: number) : boolean {
    if (duration === 1) {
      let variant = this.product.variants.find(v => v.productDurationType === DurationOptionType.lessThanFifteen)
      return variant !== undefined && variant.enabled
    } else {
      let variant = this.product.variants.find(v => v.productDurationType === DurationOptionType.moreThanFifteen)
      return variant !== undefined && variant.enabled
    }
  }

  initialiseHasDiscountvalues () {
    this.productHasDiscount = this.product.discountValue > 0
    if (this.product.variants.length > 0) {
      let jobOfferVariantOneDiscount = this.product.variants.find(v => v.productDurationType === DurationOptionType.oneMonth)?.discountValue
      let missionVariantOneDiscount = this.product.variants.find(v => v.productDurationType === DurationOptionType.lessThanFifteen)?.discountValue
      this.productFirstVariantHasDiscount = this.product.isJobOfferDepartment
        ? (jobOfferVariantOneDiscount !== undefined ? jobOfferVariantOneDiscount > 0 : false)
        : (missionVariantOneDiscount !== undefined ? missionVariantOneDiscount > 0 : false)

      let jobOfferVariantTwoDiscount = this.product.variants.find(v => v.productDurationType === DurationOptionType.oneYear)?.discountValue
      let missionVariantTwoDiscount = this.product.variants.find(v => v.productDurationType === DurationOptionType.moreThanFifteen)?.discountValue
      this.productSecondvariantHasDiscount = this.product.isJobOfferDepartment
        ? (jobOfferVariantTwoDiscount !== undefined ? jobOfferVariantTwoDiscount > 0 : false)
        : (missionVariantTwoDiscount !== undefined ? missionVariantTwoDiscount > 0 : false)
    }
  }

  getPrice (): string {
    if (this.isCohort && this.selectedCohortPriceRange) {
      return this.renderPrice(this.selectedCohortPriceRange.price)
    } else if (this.product.variants.length > 0) {
      if (this.isFirstVariantSelected) {
        let firstVariant = this.product.isJobOfferDepartment
          ? this.product.variants.find(v => v.productDurationType === DurationOptionType.oneMonth)
          : this.product.variants.find(v => v.productDurationType === DurationOptionType.lessThanFifteen)
        let price = 0
        if (firstVariant !== undefined) {
          price = firstVariant?.price - firstVariant?.discountValue
        }
        return this.renderPrice(price)
      } else {
        let secondVariant = this.product.isJobOfferDepartment
          ? this.product.variants.find(v => v.productDurationType === DurationOptionType.oneYear)
          : this.product.variants.find(v => v.productDurationType === DurationOptionType.moreThanFifteen)
        let price = 0
        if (secondVariant !== undefined) {
          price = secondVariant?.price - secondVariant?.discountValue
        }
        return this.renderPrice(price)
      }
    } else {
      return this.renderPrice(this.product.price - this.product.discountValue)
    }
  }

  getPriceBeforeDiscount (): string {
    return this.renderPrice(this.product.price)
  }

  getPriceFirstVariantBeforeDiscount (): string {
    let firstVariant = this.product.isJobOfferDepartment
      ? this.product.variants.find(v => v.productDurationType === DurationOptionType.oneMonth)
      : this.product.variants.find(v => v.productDurationType === DurationOptionType.lessThanFifteen)
    return this.renderPrice(firstVariant?.price)
  }

  getPriceSecondVariantBeforeDiscount (): string {
    let secondVariant = this.product.isJobOfferDepartment
      ? this.product.variants.find(v => v.productDurationType === DurationOptionType.oneYear)
      : this.product.variants.find(v => v.productDurationType === DurationOptionType.moreThanFifteen)
    return this.renderPrice(secondVariant?.price)
  }

  private renderPrice (price: number | undefined) :string {
    if (price === undefined) {
      return ''
    }

    let formatedPrice = ''
    if (CultureService.getCulture() === Culture.English) {
      formatedPrice = '$ ' + new Intl.NumberFormat('en-US').format(price)
    } else {
      formatedPrice = new Intl.NumberFormat('fr-CA').format(price) + ' $'
    }

    if (this.isCohort) {
      formatedPrice += ' / ' + this.translations.offer
    }
    return formatedPrice
  }

  getFirstVariantName (): string {
    if (this.isMission) {
      return this.translations.mission.variants.one
    } else if (this.isJobOffer) {
      return this.translations.jobOffer.variants.one
    } else if (this.isPermit) {
      return this.translations.permit.variants.one
    } else {
      return ''
    }
  }

  getSecondVariantName (): string {
    if (this.isMission) {
      return this.translations.mission.variants.two
    } else if (this.isJobOffer) {
      return this.translations.jobOffer.variants.two
    } else if (this.isPermit) {
      return this.translations.permit.variants.two
    } else {
      return ''
    }
  }

  firstVariantSelected () {
    if (!this.isFirstVariantSelected) {
      this.isSecondVariantSelected = false
      this.isFirstVariantSelected = true
    }
  }

  secondVariantSelected () {
    if (!this.isSecondVariantSelected) {
      this.isFirstVariantSelected = false
      this.isSecondVariantSelected = true
    }
  }

  selectCohortPriceRange (priceRange: ProductPriceRange, index: number) {
    this.selectedCohortPriceRange = priceRange
    this.quantity = this.getCohortPriceRangeMinimum(index)
  }

  getCohortPriceRangeLabel (index: number) {
    if (this.product.cohortPriceRange[index].max >= 99) {
      return this.getCohortPriceRangeMinimum(index) + '+ ' + this.translations.offer + 's'
    }
    return this.getCohortPriceRangeMinimum(index) + ' - ' + this.product.cohortPriceRange[index].max + ' ' + this.translations.offer + 's'
  }

  getCohortPriceRangeMinimum (index: number): number {
    return index === 0 ? 1 : this.product.cohortPriceRange[index - 1].max + 1
  }

  get getCohortIndustry (): string {
    var industries: Array<string> = []
    this.product.industries.forEach(element => {
      var industry = this.industriesList.find(p => p.value === element)?.label || ''
      if (industry !== '') {
        industries.push(industry)
      }
    })
    return industries.join(' - ')
  }

  get getCohortDateLabel(): string {
    var startDate = new Date(this.product.startDate)
    var endDate = new Date(this.product.endDate)

    return startDate.toLocaleDateString(this.cultureCode, this.getDateOptions) + ' - ' + endDate.toLocaleDateString(this.cultureCode, this.getDateOptions)
  }

  get getDateOptions () : object {
    return { year: 'numeric', month: 'long', day: 'numeric' }
  }

  getBackgroundColor (): string {
    if (this.isCohort || this.isMission) {
      return 'background-color: #004a7c'
    } else if (this.isJobOffer) {
      return 'background-color: #00b7ce'
    } else if (this.isPermit) {
      return 'background-color: #7a2f6f'
    } else {
      return 'background-color: #ffffff'
    }
  }

  addItem (product:Product) {
    this.add(product)
  }

  add (product: Product) {
    this.$emit('product-added', { product: product, quantity: this.quantity })
  }

  getTextColor (): string {
    if (this.isCohort) {
      return 'color:#004a7c'
    } else if (this.isJobOffer) {
      return 'color:#00b7ce'
    } else if (this.isPermit) {
      return 'color:#7a2f6f'
    } else {
      return 'color:#000000'
    }
  }

  addToCart () {
    this.isLoading = true
    if (this.product.variants.length > 0) {
      if (this.isCohort) {
        this.addItem(this.product)
      }
      if (this.isFirstVariantSelected) {
        let variant = this.product.isJobOfferDepartment
          ? this.product.variants.find(v => v.productDurationType === DurationOptionType.oneMonth)
          : this.product.variants.find(v => v.productDurationType === DurationOptionType.lessThanFifteen)
        if (variant !== undefined) {
          this.addItem(variant)
        }
      } else if (this.isSecondVariantSelected) {
        let variant = this.product.isJobOfferDepartment
          ? this.product.variants.find(v => v.productDurationType === DurationOptionType.oneYear)
          : this.product.variants.find(v => v.productDurationType === DurationOptionType.moreThanFifteen)
        if (variant !== undefined) {
          this.addItem(variant)
        }
      }
    } else {
      this.addItem(this.product)
    }
  }
}
