import { DurationOptionType } from '@/dashboard/employer-package/models/DurationOptionType'
import { ProductType } from '@/dashboard/employer-package/models/ProductType'

export interface AdminBillingResponse {
    count: number;
    orders: AdminOrder[];
}

export interface AdminOrder {
    id: number;
    created: string;
    createdById: number;
    orderStatus: OrderStatus;
    paymentType: PaymentType;
    paymentIntentId: string;
    items: AdminOrderItem[];
    contracts: AdminOrderContract[];
    recruiterName: string;
    recruiterEmailAddress: string;
    companyName: string;
    companyId: string;
    totalPrice: number;
    invoiceId:string;
    purchaseOrder:string;
}

export enum OrderStatus {
    New,
    PaymentReceived,
    PaymentFailed,
    InProgress,
    Completed,
    Closed,
    Canceled,
    Expired
}

export enum PaymentType {
    CreditCard,
    Interac
}

export interface AdminOrderItem {
    id: number;
    skuName: string;
    skuGuid: string;
    quantity: number;
    startDate: string;
    endDate: string;
    productType: ProductType;
    totalDisplay: number;
    totalUsed: number;
    productDurationType: DurationOptionType;
    expirationNotificationReceived: boolean;
    missionCrmId: string;
}

export interface AdminOrderContract {
    orderId: number;
    productSkuId: number;
    attachmentId: number;
    attachmentGuid: string;
    attachmentName: string;
    attachmentUrl: string;
    isSigned: boolean;
}
