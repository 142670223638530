import ProductContract from '@/dashboard/employer-package/models/ProductContract'
import { ShoppingCart } from '@/dashboard/employer-package/models/ShoppingCart'
import { ShoppingCartItem } from '@/dashboard/employer-package/models/ShoppingCartItem'
import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'

export class ShoppingCartService {
  public static addItem (companyId:number, skuGuid:string, culture:string, isCallFromShoppingCartPage: boolean = false, quantity: number = 1) : AxiosPromise<ShoppingCart> {
    return BASE_API.post('shoppingcart/addItem', {}, {
      params: {
        companyId: companyId,
        skuGuid: skuGuid,
        culture: culture,
        isCallFromShoppingCartPage: isCallFromShoppingCartPage,
        quantity: quantity
      }
    })
  }

  public static getShoppingCart (culture:string, isCallFromShoppingCartPage: boolean = false) : AxiosPromise<ShoppingCart> {
    return BASE_API.get('shoppingcart/getShoppingCart', {
      params: {
        culture: culture,
        isCallFromShoppingCartPage: isCallFromShoppingCartPage
      }
    })
  }

  public static getIsShoppingCartEmpty () : AxiosPromise<boolean> {
    return BASE_API.get('shoppingcart/getIsShoppingCartEmpty')
  }

  public static updateQuantity (cartItemId: number, quantity:number, culture:string, isCallFromShoppingCartPage: boolean = false) : AxiosPromise<ShoppingCart> {
    return BASE_API.patch('shoppingcart/updateItemQuantity', {}, {
      params: {
        cartItemId: cartItemId,
        quantity: quantity,
        culture: culture,
        isCallFromShoppingCartPage: isCallFromShoppingCartPage
      }
    })
  }

  public static updatePurchaseOrder (cartItemId: number, purchaseOrder:string) : AxiosPromise<ShoppingCart> {
    return BASE_API.patch('shoppingcart/updatePurchaseOrder', {}, {
      params: {
        cartItemId: cartItemId,
        purchaseOrder: purchaseOrder
      }
    })
  }

  public static deleteItem (cartItemId: number, culture:string, isCallFromShoppingCartPage: boolean = false) : AxiosPromise<ShoppingCart> {
    return BASE_API.delete('shoppingcart/deleteItem', {
      params: {
        cartItemId: cartItemId,
        culture: culture,
        isCallFromShoppingCartPage: isCallFromShoppingCartPage
      }
    })
  }

  public static deleteShoppingCart (cartId: number) : AxiosPromise<any> {
    return BASE_API.delete('shoppingcart/deleteShoppingCart', {
      params: {
        cartId: cartId
      }
    })
  }

  public static itemDurationChanged (item: ShoppingCartItem, culture:string, isCallFromShoppingCartPage: boolean = false) : AxiosPromise<ShoppingCart> {
    return BASE_API.put('shoppingcart/itemDurationChanged', item, {
      params: {
        culture: culture,
        isCallFromShoppingCartPage: isCallFromShoppingCartPage
      }
    })
  }

  public static mergeItem (cartItemId: number, variantSkuGuid: string, culture: string, isCallFromShoppingCartPage: boolean = false) : AxiosPromise<ShoppingCart> {
    return BASE_API.post('shoppingcart/mergeItem', {}, {
      params: {
        cartItemId: cartItemId,
        variantSkuGuid: variantSkuGuid,
        culture: culture,
        isCallFromShoppingCartPage: isCallFromShoppingCartPage
      }
    })
  }

  public static getProductsContracts (culture: string) : AxiosPromise<Array<ProductContract>> {
    return BASE_API.get('shoppingcart/getProductsContracts', {
      params: {
        culture: culture
      }
    })
  }

  public static getDownloadProductContractUrl(attachmentGuid: string) : string {
    return BASE_API.defaults.baseURL + '/' + 'shoppingcart/downloadProductContract?attachmentGuid=' + attachmentGuid
  }
}
